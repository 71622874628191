import React, { Component } from 'react';
import logo from '../assets/images/logo.png';


import {
    Container,
    Row,
    Col
} from 'reactstrap';

import moment from 'moment';


function formatPrice(price, dec=2) {
    return parseFloat(price).toFixed(dec)
}


export default class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }




    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on("adminFetchOne", (data) => {
            console.log(data);
            this.setState(data)

        });

        this.props.socketIOClient.emit("adminFetchOne", { query: { _id: this.props[0].match.params.id }, collection: 'orders' });

    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }



    render() {

        let total = this.state.total;

        let parts = [0,0,0,0];







        return (
            <div className="order-account">
                <Container>

                    <div className="left">
                    Blazo d.o.o, Ul. Nika Miljanica 19
 
<br />
                        Tel.: +382 67 575 111<br/>
                        www.auto-oprema.me<br/>
www.autokozmetika.me




        </div>
                    <div className="right">
                        <img src={logo} className="img-fluid logo" />
                    </div>
                    <div className="info">
                        <h1>Blazo d.o.o</h1>
                        <div className="title">
                            <h2>PIB:</h2>
                            <h2>PDV:</h2>
                            <h2>ŽIRO RAČUN:</h2>
                            

                        </div>
                        <div className="datas">
                            <h2>02913402</h2>
                            <h2>30/31-16834-0</h2>
                            <h2>530-21418-70</h2>
                        </div>

                    </div>
                    <div className="komitet">
                        <h3>Adresa za isporuku:</h3>
                        {this.state.ShippingAddress ? <div className="box">
                            <h6>{ this.state.ShippingAddress.FirstName + ' ' + this.state.ShippingAddress.LastName}</h6>
                            <h6>{this.state.ShippingAddress.Street}</h6>
                            <h6>{this.state.ShippingAddress.City} {this.state.ShippingAddress.Zipcode}</h6>
                            <h6>{this.state.ShippingAddress.Phone} </h6>
                            <h6>{this.state.ShippingAddress.EMail}</h6>
                        </div>
                        : null}
                        <h4>Strana 1</h4>
                    </div>
                    <div className="naslov">
                        <h1>RAČUN - {this.state.orderNumber}</h1>
                    </div>
                    <table className="nasloviTabele">
                        <tr>
                            <td>Rbr</td>
                            <td>Bar kod</td>
                            <td>Naziv i broj artikla</td>
                            <td>JM.</td>
                            <td>Količina</td>
                            <td>Cijena</td>
                            <td>Iznos</td>

                        </tr>

                        {
                            this.state.products ? this.state.products.map((item, idx) => {
                                return (
                                    <tr className="white" key={idx}>

                                        <td >{idx + 1}</td>
                                        <td>{item.BarCode}</td>
                                        <td>{item.Alias + ' - ' +item.Name} / {item.package.name}</td>
                                        <td> {item.OrderUnit}</td>
                                        <td>{item.cartQuantity}</td>
                                        <td>{ formatPrice(item.price) }</td>
                                        <td>{ formatPrice(item.cartQuantity * item.price) }</td>
                                    </tr>

                                )
                            })

                                : null
                        }
                     
                    </table>
                    <div className="underTableLeft">
                 
                    </div>
                    <div className="underTableRigth">
                        <div className="leftUnderTable">
                            <h6>Ukupno:</h6>
                            {/*<h6>Iznos rabata:</h6>*/}
                        </div>
                        <div className="rightUnderTable">
                            <h6>{ formatPrice(this.state.itemsTotal) }</h6>
                            {/*<h6>0.00</h6>*/}

                        </div>
                        <div className="leftUnderTable">
                            <h6>Ukupno sa dostavom:</h6>
                            {/*<h6>Iznos rabata:</h6>*/}
                        </div>
                        <div className="rightUnderTable">
                            <h6>{ formatPrice(this.state.total) }</h6>
                            {/*<h6>0.00</h6>*/}

                        </div>

                    </div>

                    <div className="potpis">
                        <div className="col1">
                            <div className="cjena">
                                <h5>ZA UPLATU: &ensp;&ensp;&ensp; { formatPrice( this.state.total ) } € </h5>

                            </div>
                            <h6>Robu izdao:</h6>
                            <hr></hr>
                        </div>
                        <div className="col2">
                            <div className="cjena">

                                {/*<h5>SLOVIMA: dvestotinesedamdesetsedam i 75/100</h5>*/}
                            </div>
                            <h6>Pakovao :</h6>
                            <hr></hr>

                        </div>
                        <div className="col3">

                            <h6>Robu preuzeo :</h6>
                            <hr></hr>

                        </div>
                    </div>
                </Container>


            </div>
        )
    }

}



import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/\s\s+/g, ' ');
    str = str.replace(/ /g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\,/g, '-');
    str = str.replace(/š/g, 's');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/ž/g, 'z');
    return str;
}

class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        //console.log(this.props)
        //console.log(generateAlias("ćčććasd"))
        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <input className={this.props.error ? 'required' : null} type='text' onChange={(e) => {

                    if (this.props.multilang) {
                        let value = this.props.value;
                        if (!value) {
                            value = {};
                        }
                        value[this.props.lang] = e.target.value;

                        
                        this.props.onChange(value);
                    } else {
                        if (this.props.generateAlias)
                        this.props.onChange(generateAlias(e.target.value));

                        else
                        this.props.onChange(e.target.value);
                    }
                    this.forceUpdate();


                }} value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value} placeholder={this.props.placeholder} />
            </div>
        );
    }
}

export default Text;

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/\s\s+/g, ' ');
    str = str.replace(/ /g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\,/g, '-');
    str = str.replace(/š/g, 's');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/ž/g, 'z');
    return str;
}

class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <TagsInput value={this.props.value ? this.props.value : []} onChange={this.props.onChange} />
    
            </div>
        );
    }
}

export default Tags;